<template>
    <div class="login-box">
        <h4>Reset Password</h4>
        <hr/>

        <div class="alert alert-warning" v-if="error != null">
            <p>
                <span class="error-text">{{ error }}</span>
            </p>
        </div>

        <form class="form-group" @submit="Reset">
            <div class="input-field input-group-lg mb-3">
                <label for="email">Email</label>
                <input id="email" type="text" class="form-control" v-model="email" required pattern="^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$" title="please enter a valid email address">
            </div>
            <div class="input-field input-group-lg mb-3">
                <label for="otp">OTP</label>
                <input id="otp" type="text" class="form-control" oninput="this.value = this.value.toUpperCase()" v-model="otp" required pattern="^[A-Z0-9]{8}$" title="please enter a valid OTP">
            </div>
            <div class="input-field input-group-lg mb-5">
                <label for="password"><span>Please use between 8 and 16 characters with at least one being lowercase, uppercase, number and special character (!@#$%^&*)</span></label>
                <label for="password">New Password</label>
                <input id="password" type="password" class="form-control" v-model="password" required pattern="^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[!@#$%^&*+()])[a-zA-Z0-9!@#$%^&*]{8,16}$">
                <label for="password2">Retype Password</label>
                <input id="password2" type="password" class="form-control" v-model="password2" required pattern="^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[!@#$%^&*+()])[a-zA-Z0-9!@#$%^&*]{8,16}$">
            </div>
            <div class="input-field input-group-lg mb-5">
                <button class="btn btn-primary btn-lg">Reset Password</button>
            </div>
            <hr/>
        </form>
    </div>
</template>

<script>
import * as Common from '../../modules/common.js'

export default {
    name: 'ResetPassword',
    data() {
        return {
            email: '',
            password: '',
            password2: '',
            otp: '',
            error: null,
        }
    },
    components: {
    },
    created() {
    },
    methods: {
        Reset: function(e) {
            var vm = this;
            e.preventDefault();

            if (this.password != this.password2) {
                vm.error = "Passwords don't match";
                return
            }

            Common.POST_FORM('https://api.pexley.com/user/resetPassword', "email="+this.email+"&password="+this.password+"&otp="+this.otp, function(data, success) {
                if (success) {
                    Common.SetToken(data.access_token);
                    console.log(data.access_token);
                    const urlParams = new URLSearchParams(window.location.search);
                    if (urlParams.has('referer')) {
                    var ref = urlParams.get('referer');
                        document.location.href = ref;
                    } else {
                        document.location.href = "/dashboard/";
                    }
                } else {
                    vm.error = data;
                    console.log(data);
                }
            });
        }
    }
}
</script>

<style scoped>
a {
    color: rgb(82, 80, 253) !important;
    font-weight: 800;
    cursor: pointer;
}
h4 {
    text-align: center;
    margin: 0;
    padding: 0;
    font-weight: 800;
    font-size: 18px;
}
p {
    text-align: center;
    font-size: 14px;
    padding-bottom: 10px;
}
.login-box {
    width: 400px;
    height: auto;
    background-color: white;
    margin-top: 60px;
    border-radius: 5px;
    padding: 20px;
    margin: auto;
    border: 1px solid #E4E6E7;
    box-shadow: 0px 1px 1px rgba(0,0,0,0.4);
}
button {
    margin: auto;
    background-color: #1b61e4;
    margin: 0;
    padding: 0px 40px;
}
button i {
    font-size: 18px;
}

input:invalid {
  border: 2px solid red;
}
input:valid {
  border: 2px solid black;
}
</style>
