<template>
  <div>
    <div
      id="tooltip"
      :style="{ top: `${clientY}px`, left: `${clientX}px` }"
      v-if="show"
    >{{text}}</div>

  <h3 v-if="topic != undefined">{{topic}} SENTIMENT</h3>
  <h3 v-else>World Sentiment</h3>
    <svg id="map" width="100%" height="400" xmlns="http://www.w3.org/2000/svg">
      <g transform="translate(0 0) scale(0.6)">
        <g @mouseover="onHover" @mouseout="show = false" v-for='(m,id) in map' :key='m'>
          <path :id='id' v-if='data_fill[id] !== undefined' :d='m.d' :fill="data_fill[id]"></path>
          <path :id='id' v-if='data_fill[id] == undefined' :d='m.d' :fill="'#CCCCCC'"></path>
        </g>
      </g>
    </svg>
    <div class="theme-subtext">current sentiment for each country with green being positive and red negative</div>

  </div>
</template>

<style>
.theme-subtext {
  text-align: left;
  font-family: 'Roboto', sans-serif;
  font-size: 12px;
  font-weight: 400;
}
path
{
  fill-opacity: 1;
  stroke:white;
  stroke-opacity: 1;
  stroke-width:1;
}
path:hover
{
  fill-opacity: 0.8;
}
#tooltip {
  border: 1px solid black;
  padding: 5px;
  position: absolute;
  background-color: white;
  width:auto;
  text-align:right;
  display: inline;
  pointer-events:none;
}
</style>

<script>
import * as Common from '../../modules/common.js'
import * as Map from './maps/map_world.js'
import moment from "moment";
import Popper from "vue3-popper";

function Map_Fetch(vm, topic) {
  var startDate = moment().subtract(2, 'days').format('YYYY-MM-DD');
  var endDate = moment().format('YYYY-MM-DD');

  Common.AJAX_GetJSON("https://api.pexley.com/articles/GetCountryAggregation/"+JSON.stringify({"startDate":startDate, "endDate":endDate, "query":"", topic:topic}), {}, function(data, success) {
    if (data.length == 0) return;

    var m = [];
    for (var k=0; k<data.length; k++) {
      var d = data[k];
      m[d.country] = {sentiment:d.sentiment, volume:d.volume};
    }
    var max_volume = 0;
    for (let i in m) {
      if (m[i].volume > max_volume)
        max_volume = m[i].volume;
    }

    const clamp = (num, min, max) => Math.min(Math.max(num, min), max);
    var colorscale =  [
              [0, '#933a00'],
              [0.111111111111,'#c05023'],
              [0.222222222222,'#df6c51'],
              [0.333333333333,'#f18f80'],
              [0.444444444444,'#f4b7b2'],
              [0.555555555556,'#99d8ca'],
              [0.666666666667,'#76caa7'],
              [0.777777777778,'#58bb85'],
              [0.888888888889,'#38ac63'],
              [1,'#009d42']
            ];
    let max = 0.0000001, min=-0.00000001;
    for (let k in m) {
      m[k].sentiment = clamp(m[k].sentiment, -10, 10);
    }
    for (let k in m) {
      if (m[k].sentiment > max) {
        max = m[k].sentiment;
      }
      if (m[k].sentiment < min) {
        min = m[k].sentiment;
      }
    }
    for (let k in m) {
      if (k == "UK") k = "GB";
      let sentiment = m[k].sentiment;
      if (sentiment > 0) {
        sentiment = 0.5 + clamp(sentiment / max, -1.0, 1.0)*0.5;
      } else {
        sentiment = 0.5 + clamp(sentiment / min, -1.0, 1.0)*-0.5;
      }
      vm.data_fill[k] = colorscale[Math.floor(sentiment*9.9999)][1];
    }
  });
}

export default {
  //runtimeCompiler: true,
  name: 'Map',
  components: {
  },
  props: {
    topic:String,
  },
  created() {
  },
  mounted() {
    Map_Fetch(this, this.topic);
  },
  methods: {
    onHover(evt) {
      var e = evt.target;
      //var dim = document.getElementById("map").getBoundingClientRect();
      var x = evt.clientX + window.pageXOffset;// - dim.left;
      var y = evt.clientY + window.pageYOffset;// - dim.top;
      //const { clientX, clientY } = evt;
      this.show = true;
      this.clientX = x;
      this.clientY = y - 60;
      this.text = this.map[e.id].name;
      //let svg = document.querySelector("svg");
      //evt.fromElement.appendChild(e);
    },
  },
  data() {
    return {
      map:Map.mapPaths,
      data_fill:[],
      show: false,
      clientX: 0,
      clientY: 0,
      text:null,
    }
  }
}
</script>
