<template>
  <div class="col-12">
  <div class="article_ad-card">
      <div class="article_ad-header">
        Advert
      </div>

      <div>
        <div class="row">
          <div class="col-12">
<!--ins class="adsbygoogle" style="display:block; text-align:center;" data-ad-layout="in-article" data-ad-format="fluid" data-ad-client="ca-pub-7129978262157669" data-ad-slot="5733462159"></ins-->
<ins class="adsbygoogle"
     style="display:block"
     data-ad-format="fluid"
     data-ad-layout-key="-67+dp-a-5a+k6"
     data-ad-client="ca-pub-7129978262157669"
     data-ad-slot="1172211710"></ins>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<style>
.article_ad-card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: rgb(251, 251, 249);
  background-clip: border-box;
  border: 0px solid rgba(0,0,0,.125);
  border-radius: 0.25rem;
  margin:0px 4px 16px 4px;
  text-align:left;
}
.article_ad-header {
  background-color: rgb(191, 102, 40);
  color:#fff;
  font-family: 'Roboto', sans-serif;
  font-size: 18px;
  font-weight: 900;
  padding:4px 8px;
}
.article_ad-body {
  padding:12px 18px 12px 18px;
}
</style>

<script>

export default {
  //runtimeCompiler: true,
  name: 'ArticleAd',
  props: {
  },
  components: {
  },
  created() {
  },
  mounted() {
    var adsbygoogle;
    (adsbygoogle = window.adsbygoogle || []).push({});
  },
  methods: {
  },
  data() {
    return {
    };
  }     
}
</script>
