<template>
    <div class="login-box">
        <h4>Login</h4>
        <hr/>

        <div class="alert alert-warning" v-if="error != null">
            <p>
                <span class="error-text">{{ error }}</span>
            </p>
        </div>

        <form class="form-group" @submit="Login">
            <div class="input-field input-group-lg mb-3">
                <label for="email">Email</label>
                <input id="email" type="text" class="form-control" v-model="email" required>
            </div>
            <div class="input-field input-group-lg mb-5">
                <label for="password">Password</label>
                <input id="password" type="password" class="form-control" v-model="password" required>
            </div>
            <div class="input-field input-group-lg mb-5">
                <button class="btn btn-primary btn-lg">Log In</button>
            </div>
            <hr/>
            <p>Forgot your password - <a @click="SwitchToReset">Reset</a></p>
            <p>Don't have an account - <a @click="SwitchToSignup">Sign Up</a></p>
        </form>
    </div>
</template>

<script>
import * as Common from '../../modules/common.js'

export default {
    name: 'Login',
    data() {
        return {
            email: '',
            password: '',
            error: null,
        }
    },
    methods: {
        SwitchToSignup: function() {
            window.location.href = '/signup/';
        },
        SwitchToReset: function() {
            this.$parent.swapComponent('Reset')
        },
        Login: function(e) {
            var vm = this;

            Common.POST_FORM('https://api.pexley.com/user/loginPassword', "email="+encodeURIComponent(this.email)+"&password="+encodeURIComponent(this.password), function(data, success) {
                if (success) {
                    Common.SetToken(data.access_token);
                    console.log(data.access_token);
                    const urlParams = new URLSearchParams(window.location.search);
                    if (urlParams.has('referer')) {
                    var ref = urlParams.get('referer');
                        document.location.href = ref;
                    } else {
                        document.location.href = "/dashboard/";
                    }
                } else {
                    vm.error = data;
                    console.log(data);
                }
            });

            e.preventDefault();
        }
    }
}
</script>

<style scoped>
a {
    color: rgb(82, 80, 253) !important;
    font-weight: 800;
    cursor: pointer;
}
h4 {
    text-align: center;
    margin: 0;
    padding: 0;
    font-weight: 800;
    font-size: 18px;
}
p {
    text-align: center;
    font-size: 14px;
    padding-bottom: 10px;
}
.login-box {
    width: 400px;
    height: auto;
    background-color: white;
    margin-top: 60px;
    border-radius: 5px;
    padding: 20px;
    margin: auto;
    border: 1px solid #E4E6E7;
    box-shadow: 0px 1px 1px rgba(0,0,0,0.4);
}
button {
    margin: auto;
    background-color: #1b61e4;
    margin: 0;
    padding: 0px 40px;
}
button i {
    font-size: 18px;
}
</style>
