<template>
  <!--img alt="Vue logo" src="./assets/logo.png"-->
  <div>
    <Header></Header>

    <div style="margin-top:40px;">
    <component :is="RegisterComponent"></component>
    </div>

    <Footer></Footer>
  </div>
</template>

<style>
/*@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap');*/
</style>

<script>
import Login from '../components/login/login.vue'
import Reset from '../components/reset/reset_password.vue'
import Reset2 from '../components/reset/reset_password2.vue'
import * as Common from '../modules/common.js'
import Footer from '../components/headers/footer.vue'
import Header from '../components/headers/header.vue'

export default {
  //runtimeCompiler: true,
  name: 'Index',
  components: {
    Login,
    Reset,
    Reset2,
    Footer,
    Header,
  },
  mounted() {
    var token = Common.GetToken();
    console.log(token)
  },
  methods: {
    swapComponent: function(component) {
      this.RegisterComponent = component;
    }
  },
  data() {
    return {
      RegisterComponent: `Login`
      //RegisterComponent: `Reset`
    };
  }     
}
</script>
