<template>
  <div>
    <header>
      <nav class="navbar navbar-light bg-light">
        <div class="ms-4">
          <a class="navbar-brand bcolor" href="/"><img src="/img/logo.png" width="160"></a>
        </div>
      </nav>
    </header>
  </div>
</template>

<style>
.bcolor {
  background-color: #2c4763;
}
</style>

<script>
export default {
  //runtimeCompiler: true,
  name: 'Footer',
  components: {
  },
  created() {
  },
  methods: {
  },
  data() {
    return {
    };
  }     
}
</script>
