<template>
  <div class="col-12">
  <div class="article-card">
      <div v-if="article.topic != null" class="article-header">
          {{article.topic.join(' / ').camelize()}}
      </div>
      <div v-else class="article-header">
      </div>

<MqResponsive :target="['md','lg', 'xl', 'xxl']">
    <div v-if="article.imagelink.length > 1">
      <div class="row">
        <div class="col-4">
          <img @click="open(article.id)" class="image" v-bind:src="article.imagelink" alt="title" />
        </div>
        <div class="col-8 article-body">
          <div @click="openSource(article.id)" class="feed"><span><i class="fa fa-globe" aria-hidden="true"></i>      {{article.feed}}</span></div>
          <div @click="open(article.id)" class="header">{{article.title}}</div>
          <div @click="open(article.id)" class="text" v-html="article.description.clip()"></div>
          <div class="subtext"><span>{{article.pubdate.localDate()}}</span></div>
          <div v-if="article.keyword != null" class="space">
            <span class="topics badge rounded-pill" v-for='(k) in article.keyword' :style="{'background-color':TopicColors[k.split('|')[0]]}" :key='k'>{{k.split('|')[1]}}</span>
          </div>
          <div v-if="article.country != null" class="space">
            <span class="country badge rounded-pill" v-for='(k) in article.country' :key='k'>{{getCountryName(k)}}</span>
          </div>
        </div>
      </div>
    </div>

    <div v-if="article.imagelink.length <= 1">
      <div class="row article-body">
        <div class="col-12">
          <div class="feed"><span><i class="fa fa-globe" aria-hidden="true"></i>      {{article.feed}}</span></div>
          <div @click="open(article.id)" class="header">{{article.title}}</div>
          <div @click="open(article.id)" class="text" v-html="article.description.clip()"></div>
          <div class="subtext"><span>{{article.pubdate.localDate()}}</span></div>
          <div v-if="article.keyword != null" class="space">
            <span class="topics badge rounded-pill" v-for='(k) in article.keyword' :style="{'background-color':TopicColors[k.split('|')[0]]}" :key='k'>{{k.split('|')[1]}}</span>
          </div>
          <div v-if="article.country != null" class="space">
            <span class="country badge rounded-pill" v-for='(k) in article.country' :key='k'>{{getCountryName(k)}}</span>
          </div>
        </div>
      </div>
    </div>
 </MqResponsive>

<MqResponsive :target="['xs','sm']">
    <img v-if="article.imagelink.length > 1" @click="open(article.id)" class="image" v-bind:src="article.imagelink" alt="title" />
    <div class="article-body">
      <div class="row">
        <div class="col-12">
      <div class="feed"><span><i class="fa fa-globe" aria-hidden="true"></i>      {{article.feed}}</span></div>
      <div @click="open(article.id)" class="header">{{article.title}}</div>
      <div @click="open(article.id)" class="text" v-html="article.description.clip()"></div>
      <div class="subtext"><span>{{article.pubdate}}</span></div>
      <div v-if="article.keyword != null" class="space">
        <span class="topics badge rounded-pill" v-for='(k) in article.keyword' :style="{'background-color':TopicColors[k.split('|')[0]]}" :key='k'>{{k.split('|')[1]}}</span>
      </div>
      <div v-if="article.country != null" class="space">
        <span class="country badge rounded-pill" v-for='(k) in article.country' :key='k'>{{getCountryName(k)}}</span>
      </div>
    </div>
    </div>
    </div>
</MqResponsive>

    </div>
  </div>
</template>

<style>
.article-card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: rgb(251, 251, 249);
  background-clip: border-box;
  border: 0px solid rgba(0,0,0,.125);
  border-radius: 0.25rem;
  margin:0px 4px 16px 4px;
  text-align:left;
}
.article-header {
  background-color: rgb(191, 102, 40);
  color:#fff;
  font-family: 'Roboto', sans-serif;
  font-size: 18px;
  font-weight: 900;
  padding:4px 8px;
  min-height: 26px;
}
.article-body {
  padding:12px 18px 12px 18px;
}
.space {
  margin-top: 8px;
}
.topics {
  margin:0px 2px;
  float:left;
  font-family: 'Roboto', sans-serif;
  font-size: 12px;
  font-weight: 700;
}
.country {
  margin:0px 2px;
  float:left;
  font-family: 'Roboto', sans-serif;
  font-size: 12px;
  font-weight: 700;
  background-color:#f2f262;
  color:rgb(79, 20, 20);
}
.header {
  text-align: left;
  font-family: 'Roboto', sans-serif;
  font-size: 20px;
  font-weight: 700;
  line-height: 28px;
  color:rgb(26, 26, 26);
  margin:0px 0px 8px 0px;
  cursor: pointer;
}
.feed {
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  font-weight: 400;
  margin:0px 0px 8px 0px;
  color:rgb(160, 89, 17);
  cursor: pointer;
}
.text {
  text-align: left;
  font-family: 'Roboto', sans-serif;
  font-size: 17px;
  font-weight: 400;
  line-height: 24px;
  margin:0px 0px 8px 0px;
  color:rgb(77, 77, 77);
  cursor: pointer;
}
.subtext {
  text-align: left;
  font-family: 'Roboto', sans-serif;
  font-size: 12px;
  font-weight: 300;
}
.image {
  max-width: 100%;
  margin:0px 0px;
  cursor: pointer;
}
</style>

<script>
import * as Common from '../../modules/common.js'
import moment from "moment"
import { MqResponsive } from "vue3-mq"

var TopicColors = {
  'TECHNOLOGY':'#007bff',
  'TRADE':'#b010f2',
  'REGULATION':'#6f42c1',
  'FINANCIAL':'#e83e8c',
  'ETHICS':'#dc3545',
  'PROTECTIONISM':'#fd7e14',
  'DOMESTIC TERRORISM':'#e48f1b',
  'CIVIL UNREST':'#28a745',
  'CLIMATE':'#20c997',
  'WEAPON':'#17a2b8',
  'INFECTIOUS DISEASE':'#6c757d',
  'POVERTY':'#1f77b4',
  'CORRUPTION':'#ff7f0e',
  'ISLAMIST':'#2ca02c',
  'TERRORISM':'#d62728',
  'CONSPIRACY THEORY':'#9467bd',
  'EXTREMIST':'#8c564b',
  'RACISM':'#e377c2',
  'ENERGY':'#7f7f7f',
  'CLIMATE CHANGE':'#bcbd22',
  'TRAVEL':'#17BECF',
  'MUSIC BLUES':'#54b8a6',
  'MUSIC COUNTRY':'#54b8a6',
  'MUSIC EASY LISTENING':'#54b8a6',
  'MUSIC ELECTRONIC':'#54b8a6',
  'MUSIC FOLK':'#54b8a6',
  'MUSIC HIP HOP':'#54b8a6',
  'MUSIC JAZZ':'#54b8a6',
  'MUSIC POP':'#54b8a6',
  'MUSIC R&B':'#54b8a6',
  'MUSIC ROCK':'#54b8a6',
  'MUSIC METAL':'#54b8a6',
  'MUSIC PUNK':'#54b8a6',
  'MUSIC SKA':'#54b8a6',
  'MUSIC':'#54b8a6',
  'PRESIDENTIAL RACE 2024':'#f8a4a6',
};

String.prototype.camelize = function () {
var separateWord = this.toLowerCase().split(' ');
	for (var i=0; i<separateWord.length; i++) {
	separateWord[i] = separateWord[i].charAt(0).toUpperCase() +
	separateWord[i].substring(1);
	}
	return separateWord.join(' ');
}

String.prototype.localDate = function () {
//  var date = new Date(this+" UTC");
//  return date.toLocaleString(undefined, {weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', hour:'numeric', minute:'numeric', timeZoneName: 'long' });
  var t = this.split(/[- :]/);
  return new Date(Date.UTC(t[0], t[1] - 1, t[2], t[3], t[4], t[5]));
}

String.prototype.clip = function () {
  if (this.length > 400)
    return this.substring(0, 400)+"…";
  else
    return this;
}

export default {
  //runtimeCompiler: true,
  name: 'Article',
  props: {
    article:Object,
  },
  components: {
    MqResponsive,
  },
  created() {
    this.TopicColors = TopicColors;
  },
  mounted() {
  },
  methods: {
    open: function(id) {
       //window.open(url, '_blank');
       window.location.href = "/article/?a="+id;
    },
    openSource: function(id) {
       //window.open(url, '_blank');
       window.location.href = "/search/?source="+id;
    },
    getCountryName: function(countryCode) {
      if (Object.prototype.hasOwnProperty.call(Common.isoCountries, countryCode)) {
            return Common.isoCountries[countryCode];
        } else {
            return countryCode;
        }
    }
  },
  data() {
    return {
    };
  }     
}
</script>
