<template>
  <div>
    <h3>Latest News</h3>
    <!--div class="theme-subtext">time period = 1week</div-->
    <div class="theme-card" v-for='(a,i) in articleList' :key='a'>
      <div class="row">
        <div class="col-8"> 
          <div class="theme-feed"><i class="fa fa-globe" aria-hidden="true"></i> {{a.feed}}</div>
        </div>
        <div class="col-4">
          <div class="theme-time">{{timeSince(a.pubdate)}}</div>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <div @click="open(a.id)" class="theme-subtext">{{a.title}}</div>
        </div>
      </div>
      <hr v-if="i < articleList.length-1" style="margin: 6px 6px;" />
    </div>
  </div>
</template>

<style>
.theme-card {
  margin:0px 8px;
}
.theme-header {
  text-align: left;
  font-family: 'Roboto', sans-serif;
  font-size: 20px;
  font-weight: 900;
}
.theme-subtext {
  text-align: left;
  font-family: 'Roboto', sans-serif;
  font-size: 15px;
  font-weight: 500;
  color:rgb(63, 63, 63);
  margin: 0px 0px;
  cursor: pointer;
}
.theme-feed {
  text-align: left;
  font-family: 'Roboto', sans-serif;
  font-size: 12px;
  font-weight: 700;
  color: #a96d19;
}
.theme-time {
  text-align: left;
  font-family: 'Roboto', sans-serif;
  font-size: 12px;
  font-weight: 700;
  color:rgb(120, 120, 120);
  text-align: right;
}
</style>

<script>
import * as Common from '../../modules/common.js'
import moment from "moment";

export default {
  //runtimeCompiler: true,
  name: 'LatestNews',
  components: {
  },
  created() {
  },
  mounted() {
    var vm = this;
    var startDate = moment().subtract(2,'day').format('YYYY-MM-DD');
    let data = JSON.stringify({"startDate":startDate,"topic":"", "page":0, "size":8,"sort":1,"language":"EN"});
    Common.AJAX_GetJSON("https://api.pexley.com/articles/GetArticles/"+data, {}, function(data, success) {
      vm.articleList = data.result;
    });
  },
  methods: {
    open: function(id) {
       window.location.href = "/article/?a="+id;
    },
    timeSince: function(date) {
      let dateTimeParts = date.split(/[- :]/);
      dateTimeParts[1]--;
      const dateObject = Date.UTC(...dateTimeParts);

      var seconds = Math.floor((new Date() - dateObject) / 1000);
      var interval = seconds / 31536000;

      if (interval > 1) {
        return Math.floor(interval) + " years ago";
      }
      interval = seconds / 2592000;
      if (interval > 1) {
        return Math.floor(interval) + " months ago";
      }
      interval = seconds / 86400;
      if (interval > 1) {
        return Math.floor(interval) + " days ago";
      }
      interval = seconds / 3600;
      if (interval > 1) {
        return Math.floor(interval) + " hours ago";
      }
      interval = seconds / 60;
      if (interval > 1) {
        if (Math.floor(interval) > 1)
          return Math.floor(interval) + " minutes ago";
        else
          return Math.floor(interval) + " minute ago";
      }
      if (seconds <= 0) {
        return "now";
      }
      return Math.floor(seconds) + " seconds";
    }
  },
  data() {
    return {
      articleList:[],
    };
  }     
}
</script>
