<template>
  <div>
    <footer class="container">
      <p>&copy; 2022 Pexley Inc - All rights reserved
        <a class="link" href="/privacy.html">Privacy</a>
        <a class="link" href="/terms.html">Terms</a>
        <a class="link" href="mailto:support@pexley.com">Contact Email</a>
      </p>
    </footer>
  </div>
</template>

<style>
footer {
  text-align: left;
  margin-top: 10px;
}
.link {
  margin-left: 40px;
  color: #404040;
}
</style>

<script>
export default {
  //runtimeCompiler: true,
  name: 'Footer',
  components: {
  },
  created() {
  },
  methods: {
  },
  data() {
    return {
    };
  }     
}
</script>
