<template>
  <div>
    <h3>Media Topics of the week</h3>
    <div style="height:400px;">
      <div ref="sunburst"></div>
    </div>
  </div>
</template>

<style>
</style>

<script>
import * as Common from '../../modules/common.js'
import Plotly from "plotly.js";
import moment from "moment";

String.prototype.camelize = function () {
var separateWord = this.toLowerCase().split(' ');
	for (var i=0; i<separateWord.length; i++) {
	separateWord[i] = separateWord[i].charAt(0).toUpperCase() +
	separateWord[i].substring(1);
	}
	return separateWord.join(' ');
}
var ChartColors = ['#007bff', '#b010f2', '#6f42c1', '#e83e8c', '#dc3545',
					'#fd7e14', '#e48f1b', '#28a745', '#20c997', '#17a2b8',
					'#6c757d', '#1f77b4', '#ff7f0e', '#2ca02c', '#d62728',
					'#9467bd', '#8c564b', '#e377c2', '#7f7f7f', '#bcbd22', '#17becf'];
var topicList = null;
var topicListMap = {};

function Make_TopicSunburst(vm, data) {
  var data2 = {
    type: "sunburst",
    ids: ["TOPICS"],
    labels: ["Topics"],
    parents: [""],
    values:  [0],
    insidetextfont: {size: 14, family:"Roboto", color: "#fff"},
    outsidetextfont: {size: 20, color: "#000"},
    leaf: {opacity: 0.8},
    marker: {colors: ['white'], line: {width: 2}},
  };

  for (var i in data) {
    var name = data[i].topic
    data2.ids.push(name)
    data2.labels.push(name.camelize())
    data2.parents.push("TOPICS")
    data2.values.push(data[i].volume)
    var color = ChartColors[topicListMap[name]];
    data2.marker.colors.push(color)
    for (var k in data[i].tag) {
      var t = data[i].tag[k]
      data2.ids.push(name+"_"+t.name)
      data2.labels.push(t.name.split("|")[1].camelize())
      data2.parents.push(data[i].topic)
      data2.values.push(t.volume)
      data2.marker.colors.push(color)
    }
  }

  var layout = {
    height:400,
    margin: {l: 0, r: 0, b: 0, t: 0},
    plot_bgcolor: 'rgba(0,0,0,0)',
    paper_bgcolor:"#FFF0",
  };

  Plotly.newPlot(vm.$refs.sunburst, [data2], layout, {responsive: true, 'displayModeBar': false});
  /*var myPlot = document.getElementById('topic-dial');
  myPlot.on('plotly_click', function(data) {
    //console.log(data);
    var trace = data.points[0];
    //console.log(trace.id,trace.entry);
    if (trace.entry && trace.id == trace.entry.toUpperCase()) {
      Set_DropDown(-1);
    } else {
      if (topicListMap.hasOwnProperty(trace.id))
        Set_DropDown(topicListMap[trace.id]);
    }
  });*/
}

function Load_TopicAggregation(vm) {
  var startDate = moment().subtract(1,'week').endOf('isoweek').subtract(1, 'week').format('YYYY-MM-DD');
  var endDate = moment().subtract(1,'week').endOf('isoweek').format('YYYY-MM-DD');
  Common.AJAX_GetJSON("https://api.pexley.com/articles/GetTopicAggregation/"+JSON.stringify({"startDate":startDate, "endDate":endDate, "interval":"week"}), {}, function(data, success) {
    topicList = [];
    topicListMap = {};
    for (var i in data) {
      topicList.push({name: data[i].topic})
      topicListMap[data[i].topic.toUpperCase()] = i;
    }
    Make_TopicSunburst(vm, data);
  });
}

export default {
  //runtimeCompiler: true,
  name: 'Footer',
  components: {
  },
  created() {
    window.addEventListener("resize", this.myEventHandler);
  },
  mounted() {
    Load_TopicAggregation(this);
  },
  methods: {
    myEventHandler(e) {
    }
  },
  data() {
    return {
      data:[{
        x: [1,2,3,4],
        y: [10,15,13,17],
        type:"scatter"
      }],
      layout:{
        title: "My graph"
      }
    };
  }     
}
</script>
