<template>
  <div>
    <cookie-consent/>
    <header>
      <nav style="text-align:left;" class="navbar navbar-expand-lg navbar-dark backcolor">
        <div class="ms-4">
          <a class="navbar-brand" href="/"><img src="/img/logo.png" height="48"></a>
        </div>

        <button class="navbar-toggler me-4" type="button" data-bs-toggle="collapse" data-bs-target="#navbarCollapse" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarCollapse">
          <ul class="navbar-nav me-auto mb-2 mb-lg-0">
            <li v-for='(i) in itemList' :key='i' class="nav-item">
              <a class="menu-option" :class="i.active ? 'menu-active' : ''" aria-current="page" :href=i.url>{{i.name}}</a>
            </li>
          </ul>

          <div class="me-4 mt-1 menu-items">
            <a class="menu-item" v-on:click="Login()"><div class="fa fa-lg fa-sign-in" ><span>Login</span></div></a>
          </div>
        </div>
      </nav>

      <nav style="text-align:left;" class="navbar navbar-expand-lg navbar-dark backcolor2">
          <div class="d-flex search mx-auto">
            <i class="fa fa-search"></i>
            <input v-on:keyup.enter="search" type="text" class="form-control" placeholder="Search for articles">
          </div>
      </nav>
    </header>
  </div>
</template>

<style>
.backcolor {
  background-color: rgb(113, 55, 14);
  color: #d0d2d4;
}
.backcolor2 {
  background-color: rgb(91, 44, 12);
  color: #d0d2d4;
}
.menu-items {
  font-family: 'Roboto', cursive;
/*  font-family: 'Source Sans Pro', sans-serif;*/
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #d0d2d4;
  text-align: left;
  margin-top: -3px;
  cursor: pointer;
}
.menu-active {
  color: rgb(241, 170, 88) !important;  
}
.menu-item {
  margin-left: 16px;
  font-size: 16px;
  text-decoration: none;
}
.menu-item span {
  font-family: 'Roboto', cursive;
/*  font-family: 'Source Sans Pro', sans-serif;*/
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: rgb(225, 161, 88);
  text-align: left;
  margin-top: 8px;
  margin-left: 2px;
  cursor: pointer;
}
.menu-item div {
    color: rgb(216, 161, 99) !important;
}
.menu-option {
  font-family: 'Roboto', cursive;
  font-weight: 700;
  font-size: 21px;
  text-decoration: none;
  color: rgba(155, 103, 43, 0.728);
  padding-left: 13px;
  padding-right: 13px;
}
.menu-option:hover {
  color: rgba(155, 103, 43, 1.0);
}

.cookie-consent {
  position:fixed;z-index:9999;width:100%;padding:0 0;display:-ms-flexbox;display:flex;-ms-flex-wrap:wrap;flex-wrap:wrap;-ms-flex-align:center;align-items:center;-ms-flex-pack:center;justify-content:center;background:#bfc2c5
}
.cookie-consent-compliance,.cookie-consent-message {
  margin:.5rem 1rem
}
.cookie-consent-compliance {
  cursor:pointer;color:#fff;padding:.15rem 1.5rem;background:#237afc;border:2px solid #888
}
.cookie-consent {left:0;right:0;bottom:0
}
.cookie-consent-transition-leave-active{transition:transform .75s;transition-timing-function:cubic-bezier(.75,0,0,1)
}
.cookie-consent-transition-leave-to{transform:translateY(100%)
}

.search{
  position: relative;
  width:360px;
  border: 0px solid #533102;
}

.search input{
  height: 28px;
  text-indent: 20px;
  border: 0px solid #533102;
}

.search input:focus{
  box-shadow: none;
  border: 0px solid blue;
}

.search .fa-search{
  position: absolute;
  top: 6px;
  left: 8px;
}

</style>

<script>
var $ = require('jquery');

$(".nav-link").on("click", function(){
	$(".nav-link.active").removeClass("active");
	$(this).addClass("active");
});

import CookieConsent from 'vue-cookieconsent-component'

export default {
  //runtimeCompiler: true,
  name: 'Footer',
  components: {
    CookieConsent
  },
  props: {
    topic:String,
  },
  created() {
    if (this.topic != undefined) {
      for (let i in this.itemList) {
        if (this.itemList[i].name == this.topic) {
          this.itemList[i].active = true;
          break;
        }
      }
    }
  },
  mounted() {
  },
  methods: {
     Login: function(e) {
       //window.location.href = '/login/';
       e.preventDefault();
    },
    toggleNav: function(e) {
    },
    search(e) {
      if (e.target.value != "")
        window.location.href = "/search/?search="+e.target.value;
      //e.target.value = ''
    }
  },
  data() {
    return {
      itemList:[{name:'HOME', url:'/', active:false}, {name:'CHANNELS', url:'/channel', active:false}, {name:'TOPICS', url:'/topic', active:false}, {name:'CHARTS', url:'/', active:false}],
    };
  }     
}
</script>
