<template>
    <div class="login-box">
        <h4>Reset Password</h4>
        <hr/>

        <form class="form-group" @submit="Proceed">
            <div class="input-field input-group-lg mb-3">
                <label for="email">Email</label>
                <input id="email" type="text" class="form-control" v-model="email" required pattern="^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$" title="please enter a valid email address">
            </div>

            <div class="">
                First we'll send you an email with a password reset code (OTP).
                Please click proceed to continue.
            </div>
            <br/>
            <br/>
            <div class="input-field input-group-lg mb-5">
                <button class="btn btn-primary btn-lg">Proceed</button>
            </div>
        </form>
        <hr/>
        <p>Go back to login <a @click="SwitchToLogin">login</a></p>
    </div>
</template>

<script>
import * as Common from '../../modules/common.js'
import { VueReCaptcha, useReCaptcha } from 'vue-recaptcha-v3'

export default {
    name: 'ResetPassword',
    data() {
        return {
            email: '',
            error: null,
        }
    },
    components: {
    },
    created() {
    },
    methods: {
        SwitchToLogin: function() {
            this.$parent.swapComponent('Login')
        },
        async recaptcha() {
            await this.$recaptchaLoaded()
            const token = await this.$recaptcha('login')
            return token
        },
        Proceed: function(e) {
            var vm = this;
            e.preventDefault();

            this.recaptcha().then(function(token){
                console.log("recaptcha", token);
                Common.POST_FORM('https://api.pexley.com/user/resetPasswordSendOTP', `email=`+vm.email+"&token="+token, function(data, success) {
                    console.log(success, data)
                    vm.$parent.swapComponent('Reset2')
                });
            })
        }
    }
}
</script>

<style scoped>
a {
    color: rgb(82, 80, 253) !important;
    font-weight: 800;
    cursor: pointer;
}
h4 {
    text-align: center;
    margin: 0;
    padding: 0;
    font-weight: 800;
    font-size: 18px;
}
p {
    text-align: center;
    font-size: 14px;
    padding-bottom: 10px;
}
.login-box {
    width: 400px;
    height: auto;
    background-color: white;
    margin-top: 60px;
    border-radius: 5px;
    padding: 20px;
    margin: auto;
    border: 1px solid #E4E6E7;
    box-shadow: 0px 1px 1px rgba(0,0,0,0.4);
}
button {
    margin: auto;
    background-color: #1b61e4;
    margin: 0;
    padding: 0px 40px;
}
button i {
    font-size: 18px;
}

input:invalid {
  border: 2px solid red;
}
input:valid {
  border: 2px solid black;
}
</style>
