<template>
  <div>
    <div class="theme-card" v-for='(a, i) in countryList' :key='a'>
      <div class="row" :class="(i & 1) ? 'back-l' : 'back-d'">
        <div class="col-9"> 
          <div class="theme-country">{{getCountryName(a.tag)}}</div>
        </div>
        <div class="col-3">
          <div v-if="a.yd > 0" class="theme-divergence-p">+{{(a.yd*100).toFixed(1)}}%</div>
          <div v-if="a.yd == 0" class="theme-divergence">{{(a.yd*100).toFixed(1)}}%</div>
          <div v-if="a.yd < 0" class="theme-divergence-n">{{(a.yd*100).toFixed(1)}}%</div>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
.back-d {
  background-color:rgb(248, 248, 248);
}
.back-l {
  background-color:rgb(255, 255, 255);
}
.theme-card {
  margin:0px 8px 4px 8px;
}
.theme-header {
  text-align: left;
  font-family: 'Roboto', sans-serif;
  font-size: 20px;
  font-weight: 900;
}
.theme-divergence {
  text-align: right;
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  font-weight: 500;
  color:rgb(63, 63, 63);
  margin: 0px 8px;
  cursor: pointer;
  width:50px;
}
.theme-divergence-p {
  text-align: right;
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  font-weight: 500;
  color:#008456;
  margin: 0px 8px;
  cursor: pointer;
  width:50px;
}
.theme-divergence-n {
  text-align: right;
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  font-weight: 500;
  color:#ce2b2b;
  margin: 0px 8px;
  cursor: pointer;
  width:50px;
}
.theme-country {
  text-align: left;
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  font-weight: 700;
  color:rgb(100, 100, 100);
}
</style>

<script>
import * as Common from '../../modules/common.js'
import moment from "moment";

String.prototype.camelize = function () {
var separateWord = this.toLowerCase().split(' ');
	for (var i=0; i<separateWord.length; i++) {
	separateWord[i] = separateWord[i].charAt(0).toUpperCase() +
	separateWord[i].substring(1);
	}
	return separateWord.join(' ');
}

export default {
  //runtimeCompiler: true,
  name: 'TrendCountry',
  components: {
  },
  created() {
  },
  mounted() {
    var vm = this;
    Common.AJAX_GetJSON("https://api.pexley.com/articles/GetCountryDivergence/"+JSON.stringify({"size":20}), {}, function(data, status) {
      vm.countryList = data;
    });
  },
  methods: {
     getCountryName: function(countryCode) {
      if (Object.prototype.hasOwnProperty.call(Common.isoCountries, countryCode)) {
            return Common.isoCountries[countryCode];
        } else {
            return countryCode;
        }
    }
 },
  data() {
    return {
      countryList:[],
    };
  }     
}
</script>
